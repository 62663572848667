import React from "react";

import DocumentationPageLayout from "@src/components/DocumentationPageLayout";

function WebsitePrivacy({ pageContext }) {
  return (
    <DocumentationPageLayout
      content={pageContext.websitePrivacy}
      title="Website Privacy Policy"
      seoTitle="page_titles.privacy"
      seoDescription="page_descriptions.privacy"
      type="website"
    />
  );
}

export default WebsitePrivacy;
